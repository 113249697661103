<template>
    <div class="container">
        <img src="https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/download_app.png" alt="天榜号app下载页" class="download-app">
    </div>
  </template>
  
  <script>
  import Header from '../home/header.vue';
  export default {
      components:{
          Header
      },
      mounted(){
        window.scrollTo(0,0);
        this.$store.commit('home/setCurrentGlobalMenuIndex',8)
      },
      beforeDestroy(){
        this.$store.commit('home/setCurrentGlobalMenuIndex',0)
      }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#FFFFFF;
      width: calc(100vw);
      min-width: 1440px;
      height: 770px;
    .download-app{
        width:100%;
        height: 100%;
    }
  }
  
  </style>